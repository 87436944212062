html,
body,
#root {
  height: 100%;
  width: 100%;
}

.outer-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.caregiver-login {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #F2F2F2;
  border-radius: 20px;
  padding: 30px 0px;
}

.caregiver-login img {
  width: 25%;
}

.caregiver-container {
  background-color: white;
  height: 500px;
  margin: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.caregiver-icon {
  color: #FCB040;
  font-size: 100px;
}

.caregiver-header-icon {
  color: #DD8F1D;
  font-size: 35px;
  cursor: pointer;
}

.caregiver-login-form {
  height: 50%;
}

.caregiver-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40%;
}

.caregiver-submit-button {
  background-color: #DD8F1D;
  color: white;
  font-weight: 500;
  cursor: pointer;
  pointer-events: auto;
  border: 0.0px solid transparent;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16px;
  padding: 8px 20px;
  font-weight: bold;
}

.caregiver-cancel-button {
  background-color: #f35b5b;
  color: white;
  font-weight: 500;
  cursor: pointer;
  pointer-events: auto;
  border: 0.0px solid transparent;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16px;
  padding: 8px 20px;
  font-weight: bold;
}

.css-1ty026z {
  padding: 20px !important;
}

.row {
  margin-bottom: 35px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  border-bottom: 2px solid #DD8F1D;
  height: 10%;
  background-color: #F2F2F2;
}

.header-right>span {
  color: #DD8F1D;
  font-weight: 500;
  font-size: 18px;
}

.home-container {
  height: 90%;
  background-color: #F2F2F2;
}

.footer-container {
  height: 5%;
  background-color: #DD8F1D;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: white;
  font-weight: 800;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.home-inner-container {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.home-inner-container>span {
  padding: 15px;
  color: #DD8F1D;
  font-size: 24px;
  font-weight: 500;
}

.tab {
  display: flex;
  justify-content: center;
  border: 2px solid #DD8F1D;
  border-radius: 30px;
}

.tab>div {
  width: 100%;
  cursor: pointer;
  border-radius: 30px;
}

body .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #F2F2F2;
  background-color: #DD8F1D;
  width: 50%;
  font-weight: bold;
}

body .css-1q2h7u5.Mui-selected {
  color: #F2F2F2;
  background-color: #DD8F1D;
  width: 50%;
  font-weight: bold;
}

body .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  width: 50%;
  background-color: white;
  font-weight: bold;
}

body .css-1q2h7u5 {
  width: 50%;
  background-color: white;
  font-weight: bold;
}

body .css-1aquho2-MuiTabs-indicator {
  background-color: #DD8F1D;
}

body .css-ttwr4n {
  background-color: #DD8F1D;
}

.home-menus {
  text-align: center;
  background-color: white;
  padding: 50px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.home-menus>span {
  font-size: 30px;
  font-weight: 500;
}

.caregiver-menu-icons {
  color: #DD8F1D;
  font-size: 100px;
  margin-bottom: 10px;
}

.resident-menu-icons {
  color: #DD8F1D;
  font-size: 75px;
  margin-bottom: 10px;

}

.task-container {
  margin: 15px 0px;
  height: 600px;
  overflow: auto;
}

.task-container div {
  padding: 15px 15px;
  background-color: white;
  margin: 15px 0px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.task-container div>span {
  padding-left: 15px;
}

.check-circle {
  color: #DD8F1D;
}

.css-qfso29-MuiTypography-root-MuiDialogContentText-root {
  font-size: 18px !important;
  /* padding-top: 10px; */
  /* padding-bottom: 5px; */
}

body .css-o3d33y {
  font-size: 18px !important;
}

.confirm-success-button {
  background-color: #6dd06d;
  color: white;
  font-weight: 500;
  cursor: pointer;
  pointer-events: auto;
  border: 0.0px solid transparent;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16px;
  padding: 8px 20px;
  font-weight: bold;
}

.confirm-cancel-button {
  background-color: #f35b5b;
  color: white;
  font-weight: 500;
  cursor: pointer;
  pointer-events: auto;
  border: 0.0px solid transparent;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16px;
  padding: 8px 20px;
  font-weight: bold;
}

.close-button {
  color: #f35b5b;
  font-size: 25px;
  cursor: pointer;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 15px !important;
  width: 100%;
  margin: 16px !important;
}

.css-uhb5lp {
  border-radius: 15px !important;
  /* width: 100%; */
  margin: 16px !important;
}

.disabled {
  background-color: #c0c0c0;
  cursor: not-allowed !important;
}

.css-knqc4i-MuiDialogActions-root {
  padding: 10px 0px !important;
  display: flex !important;
  justify-content: space-evenly !important;
}

body .css-1vskg8q {
  /* padding: 0px 0px 10px 0px !important; */
  display: flex !important;
  justify-content: space-evenly !important;
}

body .task-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.caregiver-title {
  display: flex;
  justify-content: center;
  /* padding: 20px 0px 10px 0px; */
  color: #DD8F1D;
  font-weight: bold;
  font-size: 20px;
}

.caregiver-content {
  /* display: flex; */
  /* justify-content: center; */
  word-wrap: break-word;
  /* Allows long words to break */
  white-space: normal;
  /* Wrap text to the next line */
  padding: 15px 10px 15px 10px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  border: 1px solid grey;
  margin-bottom: 15px;
  border-radius: 10px;
}

.caregiver-contents {
  /* display: flex; */
  /* justify-content: center; */
  word-wrap: break-word;
  /* Allows long words to break */
  white-space: normal;
  /* Wrap text to the next line */
  padding: 20px 0 10px;
  color: #DD8F1D;
  font-weight: 500;
}

.home-inner-container>div {
  margin: 15px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  font-weight: 500;
}

.checkin-input{
  display: flex;
  align-items: center;
  justify-content: center;
}

.resident-details {
  background-color: white;
  margin: 15px;
  border: 1px solid #DD8F1D;
  border-radius: 30px;
}

.resident-info {
  margin: 15px 0px;
  display: flex;
  justify-content: unset;
  align-items: center;
  border-bottom: 1px solid #DD8F1D;
  padding: 10px 0px;
}

.resident-info span:nth-child(1) {
  color: #DD8F1D;
  font-size: 18px;
  width: 40%;
  padding-left: 15px;
  padding-right: 2px;
}

.resident-info span:nth-child(2) {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  padding-left: 4px;
  width: 60%;
  padding-right: 15px;
  padding-left: 8px;
}

.sidebar-header {
  height: 10%;
  display: flex;
  align-content: center;
  border-bottom: 1px solid #c0c0c0;
  box-shadow: 0 3px 5px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  justify-content: space-between;
  padding: 15px !important;
}

.close-icon {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  /* width: 22%; */
}

.logo-sidebar {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  /* width: 55%; */
}

.sidebar-menu {
  height: 77%;
  padding: 15px 15px 0px 15px;
}

.header-menu-button {
  width: 100%;
  height: 7%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  padding: 0% 1%;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
  pointer-events: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.sidebar-version {
  height: 5%;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.manage-sidebar-menu {
  color: inherit;
  text-decoration: none;
}

.collapse {
  padding-left: 20px;
}

.manage-sidebar-menu {
  color: inherit;
  text-decoration: none;
}

.close-icon-inner {
  font-size: 40px;
  cursor: pointer;
  color: #DD8F1D;
}

.logo-sidebar img {
  width: 100%;
}

.manage-sidebar-menu.active>li {
  background-color: #E0E0E0;
}

.manage-sidebar-menu>li:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.css-8atqhb {
  padding: 4px 0px;
}

body .css-e0yuwi {
  padding: 2px 16px !important;
}

body .collapse:not(.show) {
  display: block;
}

body .css-1brzpa1 {
  width: 250px;
  background-color: #F2F2F2;
}

body .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

body .css-wf16b5 {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

body .menu-line {
  border: 1px solid darkgray;
}

.caregiver-sidemenu-icon {
  color: #DD8F1D;
  font-size: 25px;
}

.ccar-logout-button {
  height: 80%;
  min-width: 100px;
  width: 75%;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  background-color: #DD8F1D;
  cursor: pointer;
  pointer-events: auto;
  border: 0.9px solid #DD8F1D;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

body .title-container {
  padding: 4px;
  background-color: #DD8F1D !important;
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  margin: 0px;
}

.title-container>span {
  padding: 0px !important;
}

body .vitals-container {
  padding: 0px;
  background-color: transparent;
  margin: 0px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  border-radius: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 90%;
  overflow: auto;
  cursor: default;
}

body .vitals-container div {
  padding: 10px 20px 10px 20px;
  background-color: white;
  margin: 15px 0px 0px 0px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  border-radius: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.vitals-container div>span {
  padding-left: 0px;
}

.vitals-preview {
  color: #DD8F1D;
  font-size: 30px;
  cursor: pointer;
  /* margin-right: 45px; */
}

.vitals-preview-header {
  color: #DD8F1D;
  font-size: 35px;
  cursor: pointer;
  /* margin-right: 45px; */
}

body .previous {
  background-color: #DD8F1D;
  padding: 4px 4px 4px 10px !important;
  border-radius: 10px;
  width: 75%;
}

body .vitals-outer-container {
  padding: 0px;
  background-color: transparent !important;
}

.vital-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.popup-subscriber-header {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
  color: #DD8F1D;
  font-size: 20px;
  align-items: center;
}

.popup-task > div > div {
  width: 100%;
}

.popup-close {
  cursor: pointer;
  font-size: 24px;
}

body .popup-subscriber .MuiDialog-paperWidthMd {
  width: 100%;
  height: auto;
  overflow-x: hidden;
  border: 1px solid #DD8F1D;
  border-radius: 20px;
}

.btn-div {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.label {
  font-weight: 700;
}

body .css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
  margin: 0px;
}

.answer {
  padding: 0px 10px;
}

body .css-1t4vnk2-MuiDialogContent-root {
  padding: 15px 15px 0px 15px;
}

.pop-inner-row {
  display: flex;
}

.pop-inner-row .label {
  width: 50%;
}

body .css-84nhb1-MuiAutocomplete-root {
  width: 100%;
}

body .css-1tgkg2a {
  width: 100%;
}

body .MuiSnackbarContent-root {
  font-weight: bold !important;
}

body .success .MuiSnackbarContent-root {
  background-color: rgb(255, 156, 20) !important;
}

body .error .MuiSnackbarContent-root {
  background-color: red !important;
}

body .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  color: white;
}

body .css-1yxmbwk {
  color: white;
}

.loading-img {
  max-width: 65px;
  position: absolute;
  animation: fadeinout 2s infinite;
  padding: 10px 8px;
  border-radius: 50px;
  background-color: gainsboro;
  border: 2px solid #DD8F1D;
}

body .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  opacity: 0.2 !important;
}

body .css-919eu4 {
  opacity: 0.2 !important;
}

.header-left {
  display: flex;
}

.no-task {
  height: 90%;
  background-color: transparent !important;
  justify-content: center;
}

.resident-no-task {
  display: flex;
  align-items: center;
  height: 80%;
}

.vitals-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 0px 10px 0px;
  margin: 0px !important;
}

.vitals-heading>span {
  color: #DD8F1D;
  font-weight: bold;
  font-size: 24px;
  padding-left: 15px;
}

.heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.required {
  color: red;
}

.caregiver-add-button {
  background-color: #DD8F1D;
  color: white;
  font-weight: 500;
  cursor: pointer;
  pointer-events: auto;
  border: 0.0px solid transparent;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16px;
  padding: 4px 10px;
  font-weight: bold;
}

body .css-18i3v7t {
  margin: 0px;
  border-radius: 15px;
}

.caregiver-header {
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.css-2ytt0r-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
  border-radius: 10px;
}

.css-1hof3tc {
  border-radius: 10px !important;
}

body sup {
  top: -.1em;
  font-size: 1.2em;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (min-width: 420px) and (max-width: 768px) {
  .caregiver-login {
    flex-direction: column;
    background-color: white;
    justify-content: center;
  }

  .caregiver-img-container {
    height: auto;
  }

  .caregiver-login-form {
    height: auto;
  }

  .caregiver-login img {
    width: 40%;
  }

  .caregiver-container {
    background-color: #F2F2F2;
    height: 500px;
    margin: 20px;
    border-radius: 20px;
    justify-content: space-evenly;
  }

  .caregiver-icon {
    font-size: 85px;
  }

  .home-menus {
    flex-direction: column;
    padding: 50px;
  }

  .home-inner-container>div {
    margin: 12px 15px;
  }

  .task-container {
    margin: 0px 15px !important;
  }

  body .css-1w0ym84 {
    width: 85%;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .caregiver-login {
    flex-direction: column;
    background-color: white;
    justify-content: center;
  }

  .caregiver-img-container {
    height: auto;
  }

  .caregiver-login img {
    width: 40%;
  }

  .caregiver-login-form {
    height: auto;
  }

  .caregiver-container {
    background-color: #F2F2F2;
    height: 400px;
    margin: 20px;
    border-radius: 20px;
    justify-content: space-evenly;
  }

  .caregiver-icon {
    font-size: 85px;
  }

  .home-menus {
    flex-direction: column;
    padding: 30px;
  }

  .home-inner-container>div {
    margin: 10px 15px;
    padding: 15px;
  }

  .task-container {
    height: 400px;
    margin: 0px 15px !important;
  }

  body .vitals-container {
    height: 65%;
  }

  body .css-1w0ym84 {
    width: 85%;
  }

  .resident-menu-icons {
    font-size: 70px;
  }

  .home-menus>span {
    font-size: 24px;
  }

  body .previous {
    width: 80%;
  }

  body .tab {
    padding: 0px !important;
  }
}